<style scoped lang="less">
.left-size {
  display: flex;
}

.logo {
  img {
    height: 24px;
    width: 120px;
  }
}

// 用户hover背景色
.actions > :last-child :hover {
  background-color: rgb(255 255 255 / 20%);
}

.actions {
  align-items: center;
}

</style>

<template>
  <div>
    <div class="flex items-center justify-start w-full h-full">
      <div class="flex actions">
        <a-dropdown>
          <BasicIcon
            name="icon-help_outline_black_24dp "
            class="mt-2 mr-2 text-2xl text-white cursor-pointer"
            @click="router.push({name:'resumeCrx'})"
          />
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <div class="text-primaryColor-default">
                  简历助手
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <!-- <Notifications /> -->
        <user-setting class="ml-[-16px]" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { nameToRouterConfigMap } from '@/router'
import UserSetting from './user-setting.vue'
import { listenerRouteChange } from '@/mitt/route-listener'
import { useRouter, onBeforeRouteUpdate, RouteRecordNormalized } from 'vue-router'
import { useUserStore } from '@/store'
const userStore = useUserStore()
const appRoute = computed(() => router.getRoutes().find(el => el.name === 'root') as RouteRecordNormalized)


// 因为 vue-router 内部使用 Provide 将 router 对象注入到 vue 实例中，useRouter 需要使用 inject 获取 router 实例
// 而 inject 需要用在 setup 顶层，
const router = useRouter()
const parentPath = ref<Recordable[]>([])
const currentRouteTitle = ref()

function updateBreadcrumb(to) {
  parentPath.value = []
  const currentRoute = unref(to || router?.currentRoute)
  const currentRouteName = currentRoute.name as string
  currentRouteTitle.value = currentRoute?.meta?.title
  let currentRouteConfig = nameToRouterConfigMap[currentRouteName]

  while (currentRouteConfig?.meta?.parentNameForBreadcrumb) {
    currentRouteConfig
      = nameToRouterConfigMap[currentRouteConfig?.meta?.parentNameForBreadcrumb]
    parentPath.value.unshift(currentRouteConfig)
  }
}
// 监听路由变化，设置当前选中的菜单 key
const selectedKey = ref<string[]>(['']) // 当前选中的菜单项名
listenerRouteChange(newRoute => {
  if (!newRoute?.meta?.hideInMenu && newRoute.name) {
    selectedKey.value = [newRoute.name as string, ...(newRoute?.meta?.parentName || [])]
  } else if (newRoute?.meta?.actionRouterName) {
    selectedKey.value = [newRoute?.meta?.actionRouterName as string, ...(newRoute?.meta?.parentName || [])]
  }
}, true)


onBeforeRouteUpdate(to => {
  updateBreadcrumb(to)
})
updateBreadcrumb(null)


</script>
