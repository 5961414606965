import { ExtractPropTypes, PropType } from 'vue'
import { DatePickerProps } from 'ant-design-vue/es'

export const datePickerUpDownYearProps = {
  value: {
    type: String
  },
  placeholder: {
    type: String,
    default: '请选择'
  },
  upBtnText: {
    type: String,
    default: '上半年'
  },
  downBtnText: {
    type: String,
    default: '下半年'
  },
  upOption: {
    type: Object as PropType<{ label: string; value:number | string}>,
    default: () => ({
      label: '上半年',
      value: '01'
    })
  },
  downOption: {
    type: Object as PropType<{ label: string; value:number | string}>,
    default: () => ({
      label: '下半年',
      value: '07'
    })
  },
  joinStr: {
    type: String,
    default: '-'
  }
}

export type DatePickerUpDownYearProps = Partial<ExtractPropTypes<typeof datePickerUpDownYearProps>> & DatePickerProps