<!-- 选择上下半年 -->
<style scoped>
.datepicker-up-down-year {
  position: relative;
  height: 100%;
  width: 100%;
}

.show-label {
  z-index: 999;
}

.after-icon {
  display: none;
}

.show-label:hover .after-icon {
  display: block;
}

:deep(.ant-picker) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* 只隐藏最内层的显示区域 */
:deep(.ant-picker-input input) {
  opacity: 0;
}
</style>
<template>
  <div class="datepicker-up-down-year">
    <a-input
      :value="showLabel"
      class="show-label"
      :placeholder="props.placeholder"
      @blur="blurFn"
      @focus="focusFn"
      @click="clickLabel"
    >
      <template #suffix>
        <div>
          <close-circle-filled
            v-if="showLabel"
            class="after-icon"
            style="color: #BFBFBF;"
            @click="clearFn"
          />
          <calendar-outlined
            v-else
            style="color: #BFBFBF;"
            @blur="blurFn"
            @focus="focusFn"
            @click="clickLabel"
          />
        </div>
      </template>
    </a-input>
    <div>
      <a-form-item-rest>
        <a-date-picker
          v-bind="bindAttrs"
          ref="dateRef"
          v-model:value="yearValue"
          picker="year"
          :open="openDatePicker"
          @change="dateChange"
        >
          <template
            v-for="slot in Object.keys(slots)"
            #[slot]="data"
          >
            <slot
              :name="slot"
              v-bind="data || {}"
            />
          </template>
          <template #renderExtraFooter>
            <div class="flex justify-center py-2">
              <a-button
                size="small"
                :type="upDownValue===props.upOption.value?'primary':'ghost'"
                class="mr-2"
                @click="chooseFn('up')"
              >
                {{ upBtnText }}
              </a-button>
              <a-button
                size="small"
                :type="upDownValue===props.downOption.value?'primary':'ghost'"
                @click="chooseFn('down')"
              >
                {{ downBtnText }}
              </a-button>
            </div>
          </template>
        </a-date-picker>
      </a-form-item-rest>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { datePickerUpDownYearProps } from './props'
import { isString } from 'lodash-es'
const props = defineProps(datePickerUpDownYearProps)
const attrs = useAttrs()
const slots = useSlots()
const yearValue = ref()
const upDownValue = ref()
type EmitEvents = {
  (e: 'update:value', ...value): void
}
const emits = defineEmits<EmitEvents>()
const getValueByLabel = label => {
  if (props.upOption.label == label) {
    return props.upOption.value
  } else if (props.downOption.label == label) {
    return props.downOption.value
  } else {
    return undefined
  }
}
const getLabelByValue = value => {
  if (props.upOption.value == value) {
    return props.upOption.label
  } else if (props.downOption.value == value) {
    return props.downOption.label
  } else {
    return ''
  }
}
const updateValue = () => {
  if (unref(yearValue) && unref(upDownValue)) {
    emits('update:value', unref(yearValue) + props.joinStr + unref(upDownValue))
  } else {
    emits('update:value', undefined)
  }
}
watch(() => props.value, () => {
  if (props.value && isString(props.value)) {
    const arr = props.value.split(props.joinStr)

    if (arr.length === 2) {
      yearValue.value = arr[0]
      upDownValue.value = arr[1]
    } else {
      yearValue.value = undefined
      upDownValue.value = undefined
    }
  } else {
    yearValue.value = undefined
    upDownValue.value = undefined
  }
}, {
  immediate: true
})
const showLabel = ref()
const handleLabel = () => {
  if (yearValue.value && upDownValue.value && getLabelByValue(upDownValue.value)) {
    showLabel.value = `${yearValue.value}${props.joinStr}${getLabelByValue(upDownValue.value)}`
  } else {
    showLabel.value = ''
  }
}
watch([() => yearValue.value, () => upDownValue.value], () => {
  handleLabel()
  updateValue()
}, {
  immediate: true
})

const bindAttrs = computed(() => ({
  valueFormat: 'YYYY',
  ...attrs,
  ...props,
}))
const dateRef = ref()
const openDatePicker = ref(false)


const clearFn = () => {
  yearValue.value = undefined
  upDownValue.value = undefined
  updateValue()
}
const closeDatePicker = () => {
  if (yearValue.value && upDownValue.value) {
    openDatePicker.value = false
  }
}
const dateChange = v => {
  yearValue.value = v
  closeDatePicker()
}
const chooseFn = (type: 'up' | 'down') => {
  upDownValue.value = (type === 'up' ? props.upOption.value : props.downOption.value)
  closeDatePicker()
}
const clickLabel = () => {
  openDatePicker.value = true
}
const blurFn = () => {
  openDatePicker.value = false
}
const focusFn = () => {
  openDatePicker.value = true
}
</script>
